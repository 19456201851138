import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import GrapeJsParser from 'components/Helpers/GrapeJsParser';
import Webline from 'components/Layout/Webline/Webline';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useRouter } from 'next/router';
import { Fragment, memo, useEffect } from 'react';
import { ArticleDetailType } from 'types/article';
import { BlogArticleDetailType } from 'types/blogArticle';

type ArticleDetailProps = {
    article: BlogArticleDetailType | ArticleDetailType;
};

const TEST_IDENTIFIER = 'pages-blogarticle-';

export const blogArticleTextContentTwClass = 'flex flex-col w-full max-w-[800px] mx-auto mb-12';
export const blogArticleTitleTwClass = 'mb-6 block text-[45px] font-bold leading-10 text-primary';

const isBlogArticle = (article: BlogArticleDetailType | ArticleDetailType): article is BlogArticleDetailType => {
    return 'blogCategories' in article;
};

const ArticleDetail: FC<ArticleDetailProps> = ({ article }) => {
    const { defaultLocale, url } = useDomainConfig();
    const router = useRouter();

    useEffect(() => {
        const { hash } = new URL(router.asPath, url);

        if (hash.split('?')[0]) {
            document.querySelector(hash.split('?')[0])?.scrollIntoView();
        }
    }, [router.asPath, url]);

    return (
        <Webline>
            <div className={blogArticleTextContentTwClass}>
                <h1 className={blogArticleTitleTwClass} data-testid={TEST_IDENTIFIER + 'title'}>
                    {isBlogArticle(article) ? article.name : article.seoH1 ?? article.articleName}
                </h1>
                {isBlogArticle(article) && (
                    <div className="flex flex-wrap items-end">
                        {article.blogCategories.map((blogArticleCategory, index) => (
                            <Fragment key={blogArticleCategory.uuid}>
                                {blogArticleCategory.parent !== null && (
                                    <ExtendedNextLink
                                        href={blogArticleCategory.link}
                                        type="blogCategory"
                                        className="mb-4 mr-2 text-small font-medium leading-none tracking-wider text-black no-underline xs:mr-5 lg:mr-10"
                                        data-testid={TEST_IDENTIFIER + 'category-' + index}
                                    >
                                        {blogArticleCategory.name}
                                    </ExtendedNextLink>
                                )}
                            </Fragment>
                        ))}
                        <div
                            className="mb-4 text-tiny font-normal leading-none text-black"
                            data-testid={TEST_IDENTIFIER + 'date'}
                        >
                            {new Date(article.publishDate).toLocaleDateString(defaultLocale)}
                        </div>
                    </div>
                )}
                {article.text !== null && (
                    <GrapeJsParser text={article.text} uuid={article.uuid} gtmListName="article products" />
                )}
            </div>
        </Webline>
    );
};

export default memo(ArticleDetail);
